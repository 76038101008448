<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">月卡模版管理</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" style="width: 135px" @click="addTempItem()">添加模版套餐</el-button>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="addTemp()">添加模版</el-button>
            </div>
        </div>
        <el-card class="el-main sceneCheckContent">
            <div class="a-flex-rsbfs">
                <div class="a-flex-rfsfs">
                    <span class="s-search-label a-flex-rfec" style="line-height: 32px">模板选择</span>
                    <vuedraggable class="wrapper" v-model="groupIdList" :animation="300" @end='handleSortEnd'>
                        <transition-group class="a-flex-rfsc a-ml-24 a-flex-wrap">
                            <div v-for="(item) in groupIdList" 
                                :key="item.value" 
                                class="a-flex-ccc my-btn"
                                :class="item.value == groupId ? 'my-btn-active' : ''" 
                                @click="handlerChangeGroupId(item.value)">
                                <span class="a-fs-14 a-c-333">{{ item.label }}</span>
                                <img v-if="item.value == groupId" src="../../assets/icon/option-btn-check.png"
                                    style="width: 20px;height: 14px; position: absolute; bottom: -1px; right: -1px;" />
                            </div>
                        </transition-group>
                    </vuedraggable>
                </div>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="handleSave()">保存</el-button>
            </div>
            <el-table 
                ref="monthCardbatchlist" 
                @row-dblclick="dbclick"
                :cell-class-name="tableCellClassName"
                :row-style="rowStyle"
                :data="tableData" 
                :border="true"
                :span-method='mergeCols'
                style="width: 100%;" 
                height="500">
                <el-table-column prop="name" label="名称" min-width="140" fixed="left">
                    <template slot-scope="scope">
                        <span class="point-edit" :class="scope.row.name_edit?'a-c-red':'a-c-blue'">{{ scope.row.name?scope.row.name:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="promotionPrice" label="促销价/划线价" min-width="160">
                    <template slot-scope="scope">
                        <span class="point-edit" :class="scope.row.promotionPrice_edit?'a-c-red':'a-c-blue'">￥{{ util.numFormat(scope.row.promotionPrice) }}/￥{{ util.numFormat(scope.row.price) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="validity" label="有效天数(天)" min-width="100">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.validity_edit?'a-c-red':'a-c-blue'">{{ row.validity?row.validity:'-' }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="cardGroupName" label="卡组" min-width="120">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.cardGroupName_edit?'a-c-red':'a-c-blue'">{{ row.cardGroupName?row.cardGroupName:'-' }}</span>
                    </template>
                </el-table-column> -->
                <el-table-column prop="cardPrice" label="实体卡费用" min-width="100">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.cardPrice_edit?'a-c-red':'a-c-blue'">￥{{ util.numFormat(row.cardPrice) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="cardPrice" label="关联活动" min-width="200">
                    <template slot-scope="{ row }">
                        <div class="a-flex-rfsfs">
                            <div class="a-flex-rfsc a-flex-wrap" v-for="(item,index) in row.activityList" :key="item.id">
                                <span class="stationTag">
                                    {{ item.activityTitle?item.activityTitle:'-' }}
                                    <i class="el-icon-error a-fs-14" @click="removeActivity(index)"></i>
                                </span>
                            </div>
                            <i class="el-icon-circle-plus-outline a-fs-24 a-c-red" @click="chooseActivity(row.index)"></i>
                        </div>
                        
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="enableTimeText" label="开售/失效时间" min-width="420">
                    <template slot-scope="{ row }">
                        <jd-datetime-range 
                            :large="true" 
                            style="margin:-20px;padding: 0;" 
                            label="" 
                            valueFormat="timestamp" 
                            :minDate.sync="row.enableTime" 
                            :maxDate.sync="row.expireTime" />
                    </template>
                </el-table-column> -->
                <el-table-column prop="payWay" label="支付方式" min-width="160">
                    <template slot-scope="{ row }">
                        <div class="a-flex-rfsc point-edit" >
                            <span :class="row.payWay_edit?'a-c-red':'a-c-blue'">{{ row.payWay | initDic(payWayDic) }}</span>
                            <span :class="row.payWay_edit?'a-c-red':'a-c-blue'" style="margin: 0 10px" v-if="row.payWay == 3">，费用上限</span>
                            <span :class="row.payWay_edit?'a-c-red':'a-c-blue'" v-if="row.payWay == 3">￥{{ util.numFormat(row.maxPrice) }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="chargingExpireType" label="资源类型" min-width="160">
                    <template slot-scope="{ row }">
                        <div class="a-flex-rfsc point-edit" >
                            <span :class="row.chargingExpireType_edit?'a-c-red':'a-c-blue'">{{ row.chargingExpireType | initDic(chargingExpireTypeDic) }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="isTempCard" 
                    label="卡种类" 
                    min-width="120" 
                    column-key="isTempCard"
                    :filter-multiple='false'
                    :filters="[]">
                    <template slot-scope="{ row }">
                        <span>{{ row.isTempCard | initDic(isTempcardDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="cardType" 
                    label="卡类型" 
                    min-width="120" 
                    column-key="cardType"
                    :filter-multiple='false'
                    :filters="[]">
                    <template slot-scope="{ row }">
                        <span>{{ row.type | initDic(cardTypeDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="bikeTypeIdList" label="车辆" min-width="140">
                    <template slot-scope="{ row }">
                        <div class="a-flex-cfsfs">
                            <div v-for="(item,index) in row.bikeTypeIdList" :key="index">
                                {{ item.parentName?(item.parentName + '-'):'' + item.name }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                
                <el-table-column prop="promotionDescription" label="促销描述" min-width="200">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.promotionDescription_edit?'a-c-red':'a-c-blue'">{{ row.promotionDescription?row.promotionDescription:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="applicableDescription" label="适用范围描述" min-width="200">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.applicableDescription_edit?'a-c-red':'a-c-blue'">{{ row.applicableDescription?row.applicableDescription:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" prop="status" label="操作" width="70">
                    <template slot-scope="{ row }">
                        <el-tooltip class="item a-ml-12" effect="dark" content="删除" placement="top">
                            <img src="../../assets/icon/option-del-circle.png" class="a-wh-16" @click="handleDel(row)" />
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height:17px"></div>
        </el-card>
        <div class="scrollBarDiv">
            <scroll-bar :table-ref="$refs.monthCardbatchlist"></scroll-bar>
        </div>
        <edit-table-value-dialog ref="editDialog" @submitVal="submitVal"></edit-table-value-dialog>
        <edit-table-payway-dialog ref="editPayWayDialog" @submitVal="submitPayWay"></edit-table-payway-dialog>
        <le-activity-choose :multiple="false" ref="chooseActivity" :defaultData="defaultActivity" @selectChange="getActivity"></le-activity-choose>
    </div>
</template>

<script>
import util from '../../../src/utils/util'
import scrollBar from '../components/table/scrollBar.vue';
import EditTableValueDialog from './child/editTableValue-dialog.vue';
import LeActivityChoose from '../components/choose/le-activity-choose.vue';
import dayjs from 'dayjs'
import EditTablePaywayDialog from './child/editTablePayway-dialog.vue';
import vuedraggable from 'vuedraggable';
    export default {
        components:{
            scrollBar,
            EditTableValueDialog,
            LeActivityChoose,
            EditTablePaywayDialog,
            vuedraggable
        },
        data () {
            return {
                util: util,
                tableData: [],
                groupIdList: [],
                groupId: '',
                defaultActivity: null,
                cosTableData: []
            }
        },
        
        mounted () {
            this.$getDic('monthcardType').then(res=>{ this.cardTypeDic = res; })
            this.$getDic('istempcard').then(res=>{ this.isTempcardDic = res; })
            this.$getDic('isSystemDefault').then(res=>{ this.isSystemDefaultDic = res; })
            this.$getDic('activeCard').then(res=>{ this.activeCardDic = res; })
            this.$getDic('needPost').then(res=>{ this.needPostDic = res; })
            this.$getDic('showModel').then(res=>{ this.showModelDic = res; })
            this.$getDic('timeType').then(res=>{ this.timeTypeDic = res; }) 
            this.$getDic('payWay').then(res=>{ this.payWayDic = res; })
            this.$getDic('chargingExpireType').then(res=>{ this.chargingExpireTypeDic = res; })
            this.getGroupList()
            this.$eventBus.$on('addTempInfo',(res)=>{
                let cardGroupId = new Date().getTime()
                let newTemp = res.map(item=>{
                    return {
                        ...item,
                        enableTime: this.$getDay.getTimeUnix(this.$getDay.getTimeStampToStr(item.enableTime)),
                        expireTime: this.$getDay.getTimeUnix(this.$getDay.getTimeStampToStr(item.expireTime)),
                        cardGroupId: cardGroupId,
                        templateId: null
                    }
                })
                this.tableData = this.tableData.concat(newTemp)
                this.handleSave()
                this.getCosTableData()
            })
        }, 
        beforeDestroy () {
            this.$eventBus.$off('addTempInfo')
        },
        methods: {
            getGroupList () {
                this.$Axios._post({
					url: this.$Config.apiUrl.monthCardTemplateList,
					params: {
						groupName: "",
                        type: 0,//0--套餐卡模版 1--储值卡模板
					}
				}).then(({
					data
				}) => {
					this.groupIdList = data.map(item=>{
                        return {
                            ...item,
                            label: item.groupName,
                            value: item.groupId
                        }
                    })
                    this.groupId = this.groupIdList[0].value
                    this.getTableData()
				})
            },
            addTempItem () {
                // 向模板内添加套餐
                this.$router.push({
                    path:'/monthCard/monthCard-edit',
                    query: {
                        type: 'addTemp'
                    }
                })
            },
            addTemp () {
                this.$prompt('请输入模板名称', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    if(value.length > 16) {
                        return '模板名称最多输入16个字符'
                    }
                    this.$Axios._post({
                        url: this.$Config.apiUrl.addMonthCardTemplateGroup,
                        params: {
                            groupName: value,
                            type: 0,//0--套餐卡模版 1--储值卡模板
                        }
                    }).then(({
                        data
                    }) => {
                        this.getGroupList()
                    })
                }).catch(() => {
                          
                });
            },
            // 删除模板套餐
            handleDel (datas) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    let arr = this.tableData.filter(res=>{
                        return res.cardGroupId == datas.cardGroupId
                    })
                    let reqData = arr.map(item=>{
                        return item.templateId
                    })
                    this.$Axios._post({
                        url: this.$Config.apiUrl.deleteMonthCardTemplate,
                        params: {
                            idList: reqData
                        },
                        method: "post",
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.getTableData()
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                })
                .catch(_=>{})
                
            },
            // 保存
            handleSave () {
                let groupName = this.groupIdList.find(res=>{
                    return res.value == this.groupId
                }).label
                let reqData = this.tableData.map(item=>{
                    let datas = {}
                    for (let key in item) {
                        let value = item[key]
                        if(key.indexOf('_edit') == -1) {
                            datas[key] = value
                        }
                    }
                    return {
                        id: item.templateId || null,
                        groupId: this.groupId,
                        groupName: groupName,
                        type: 0,//月卡套餐模板，这里目前固定传0
                        name: item.name,
                        json: JSON.stringify(datas)
                    }
                })
                this.$Axios._post({
                    url: this.$Config.apiUrl.addMonthCardTemplate,
                    params: {
                        vos: reqData
                    },
                    method: "post",
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$message.success('保存成功')
                        this.getTableData()
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
                // this.$confirm('是否确认保存？','温馨提示')
                // .then(_=>{
                    
                    
                // })
                // .catch(_=>{})
            },
            handleSortEnd () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.monthCardTemplateGroupSort,
                    params: this.groupIdList,
                    method: "post",
                })
                .then(res=>{
                    // this.$message.success('保存成功')
                    console.log('更新排序成功');
                })
                .catch(err=>{

                })
            },
            handlerChangeGroupId (value) {
                this.groupId = value
                this.getTableData()
            },
            getTableData () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.monthCardTemplate,
                    params: {
                        groupId: this.groupId
                    },
                    method: "post",
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.tableData = res.result.data.map(item=>{
                            return {
                                ...JSON.parse(item.json),
                                templateId: item.id
                            }
                        })
                        this.getCosTableData()
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            // 给单元格绑定横向和竖向的index，这样就能确定是哪一个单元格
            tableCellClassName ({row, column, rowIndex, columnIndex}) {
                row.index=rowIndex;
                column.index=columnIndex;
            },
            rowStyle ({row, rowIndex}) {
                if(row.status == 1){
                    return {
                        'background': '#f0f9eb'
                    }
                }else if(row.status == 2){
                    return {
                        'background': 'rgb(254, 240, 240)'
                    }
                }else{
                    return {
                        'background': '#ffffff'
                    }
                }
            },
            // 获得当前双击的单元格的横竖index，然后拼接成一个唯一字符串用于判断，并赋给currentCell
            dbclick (row,column) {
                let black = ['enableTimeText','expireTimeText','isTempCard','cardType','bikeTypeIdList','isCreate','status']
                if(black.indexOf(column.property) != -1){
                    return
                }
                this.currentCell = {
                    rowIndex: row.index,
                    prop: column.property
                }
                
                if(column.property == 'promotionPrice'){
                    this.$refs['editDialog'].dialogVisible = true
                    let promotionPrice = row['promotionPrice']?row['promotionPrice']:0
                    let price = row['price']?row['price']:0
                    this.$refs['editDialog'].value1 = Number((promotionPrice/100).toFixed(2)) 
                    this.$refs['editDialog'].value2 = Number((price/100).toFixed(2))
                    this.$refs['editDialog'].label1 = '促销价'
                    this.$refs['editDialog'].label2 = '划线价'
                    this.$refs['editDialog'].title = '修改促销价/划线价'
                    this.$refs['editDialog'].inputType = 'money'
                }else if(column.property == 'cardPrice'){
                    this.$refs['editDialog'].dialogVisible = true
                    let cardPrice = row['cardPrice']?row['cardPrice']:0
                    this.$refs['editDialog'].value1 = Number((cardPrice/100).toFixed(2)) 
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'money'
                }else if(column.property == 'payWay'){
                    this.$refs['editPayWayDialog'].dialogVisible = true
                    let maxPrice = row['maxPrice']?row['maxPrice']:0
                    let payWay = row['payWay']?row['payWay']:'1'
                    this.$refs['editPayWayDialog'].form.maxPrice = Number((maxPrice/100).toFixed(2)) 
                    this.$refs['editPayWayDialog'].form.payWay = payWay
                }else if(column.property == 'chargingExpireType'){
                    this.$refs['editDialog'].dialogVisible = true
                    this.$refs['editDialog'].value1 = row[column.property]
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].valueType1 = 'radio'
                    this.$refs['editDialog'].valueDic1 = this.chargingExpireTypeDic
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'string'
                }else{
                    this.$refs['editDialog'].dialogVisible = true
                    this.$refs['editDialog'].value1 = row[column.property]
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'string'
                }
            },
            submitVal (datas) {
                if(this.currentCell.prop == 'promotionPrice'){
                    this.tableData[this.currentCell.rowIndex]['promotionPrice'] = datas.value1
                    this.tableData[this.currentCell.rowIndex]['price'] = datas.value2
                    this.$set(this.tableData[this.currentCell.rowIndex],'promotionPrice'+'_edit',true)

                }else if(this.currentCell.prop == 'chargingExpireType'){
                    if(this.chargingExpireTypeNotice){
                        this.$alert('资源类型修改后，对应卡组下的所有套餐的资源类型都会被改为对应类型，请仔细核对后提交。', '温馨提示', {
                            confirmButtonText: '我知道了',
                            showCancelButton: true,
                            cancelButtonText: '不再提示',
                            type: 'warning',
                            callback: action => {
                                if(action == 'cancel') {
                                    this.chargingExpireTypeNotice = false
                                }
                                this.tableData[this.currentCell.rowIndex][this.currentCell.prop] = datas.value1
                                this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
                            }
                        });
                    }else{
                        this.tableData[this.currentCell.rowIndex][this.currentCell.prop] = datas.value1
                        this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
                    }
                    
                }else{
                    this.tableData[this.currentCell.rowIndex][this.currentCell.prop] = datas.value1
                    this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
                }
            },
            submitPayWay (datas) {
                this.tableData[this.currentCell.rowIndex]['payWay'] = datas.payWay
                this.tableData[this.currentCell.rowIndex]['maxPrice'] = datas.maxPrice
                this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
            },
            // 当input失去焦点的时候，隐藏input
            hideInput () {
                this.currentCell = null;
            },
            // 选择添加活动
            chooseActivity (index) {
                this.defaultActivity = this.tableData[index].activityList?this.tableData[index].activityList[0]:null
                this.$refs['chooseActivity'].dialogVisible = true
                this.currentIndex = index
            },
            getActivity (datas) {
                // this.$nextTick(()=>{
                //     this.$set(this.form,'activity',datas)
                // })
                this.$set(this.tableData[this.currentIndex],'activityList',[])
                this.$set(this.tableData[this.currentIndex].activityList,0,datas)
                console.log(this.tableData);
            },
            // 删除选择的活动
            removeActivity (index) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    this.tableData[this.currentIndex].activityList.splice(index,1)
                })
                .catch(_=>{})
            },
            // 合并行
            mergeCols ({ row, column, rowIndex, columnIndex }) {
                if(columnIndex !== 1 && columnIndex !== 2 && columnIndex !== 4 && columnIndex !== 7){
                    for (let index = 0; index < this.cosTableData.length; index++) {
                        const element = this.cosTableData[index];
                        for (let c_index = 0; c_index < element.length; c_index++) {
                            if(rowIndex == element[c_index]){
                                if(c_index == 0){
                                    return {
                                        rowspan: element.length,
                                        colspan: 1
                                    }
                                }else{
                                    return {
                                        rowspan: 0,
                                        colspan: 0
                                    }
                                }   
                            }
                        }
                    }
                }
            },
            // 获取需要合并单元格的数据
            getCosTableData () {
                this.cosTableData = []
                let obj = {}
                // 将相同编号的数据储存到同一个数组中，并以该编号为key值添加到obj对象中 以便之后取用
                this.tableData.map((item,index)=>{
                    if(obj[item.cardGroupId]){
                        obj[item.cardGroupId].push(index)
                    }else{
                        obj[item.cardGroupId] = []
                        obj[item.cardGroupId].push(index)
                    }
                })
                // 将obj对象中数组长度大于1（即存在多个相同编号）的数据push到this.cosTableData中
                for (const key in obj) {
                    if(obj[key].length > 1)
                    this.cosTableData.push(obj[key])
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.monthCard{
    .s-btn-add{
        width: 105px;
    }
    /deep/ .el-table__expand-column .cell {
        // display: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
    .my-btn {
        padding: 0 22px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #C4C8CC;
        position: relative;
        margin-right: 24px;
        cursor: pointer;
        margin-bottom: 10px;
    }

    .my-btn-active {
        border: 1px solid #007AFF;
    }
}
/deep/ .sceneCheckContent{
    .el-table__column-filter-trigger{
        display: none;
    }
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
    margin-right: -18px;
}
.point-edit{
    // cursor: url('../../assets/icon/edit-mouse.png'), default;
    cursor: pointer;
}
.scrollBarDiv{
    width: auto;
    position: fixed;
    left: 157px;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 10px 52px 16px;
    z-index: 2000;
    border-top: 1px solid #EBEEF5;
}
.a-pr-4{
    padding-right: 4px;
}
.el-table .wait-row {
    // background: #ffffff;
}

.el-table .success-row {
    // background: #f0f9eb;
}
.el-table .fail-row {
    // background: rgb(253, 226, 226);
}
</style>